import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import config from "../../config/main.config"
import history from "../../helper/browserHistory";
import {Icon, Progress} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import {getWindowSize} from "../../helper/util";
import {getProjectById} from "./FundraisingUtil";
import {useLocation} from "react-router";
import renderHTML from "react-render-html";
import {getPartnerById} from "../partner/PartnerUtils";

const namespace = "projectTest"

const FundraisingProjectPage = inject("stores")(observer((props) => {
    const {textStore} = props;
    const {pathname} = useLocation();
    const [projectToShow, setProjectToShow] = useState({
        header: "",
        text: "",
        description: "",
        goal: 0,
        currentDonationAmount: 0,
        thumbnail: ""
    });
    const [partners, setPartners] = useState([]);

    useEffect(_ => {
        getProjectById(pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length)).then(project => {
                setProjectToShow(project);

                project.partners.forEach((partnerId, index) => {
                    getPartnerById(partnerId).then((partner, index) => {
                        partners.push(partner)
                        setPartners([...partners])
                    })
                })
            }
        )
    }, [])

    const sliderItems = () => {
        let allSlides = [];

        for (let i = 0; i < partners.length; i++) {
            let item = partners[i];
            allSlides.push(
                <Slide index={i} key={i}>
                    <div style={getWindowSize() <= 787 ? {} : {padding: "10px 75px"}}>
                        <img onClick={_ => {
                            if (item.link.startsWith("https://")) {
                                if (typeof window !== "undefined") {
                                    window.open(item.link);
                                }
                            } else {
                                history.push(item.link)
                            }

                        }} style={getWindowSize() <= 787 ? {
                            aspectRatio: '16/9',
                            objectFit: 'contain',
                            padding: '10px',
                            width: "100%",
                            height: "auto",
                            cursor: "pointer"
                        } : {
                            width: "100%", height: "auto", cursor: "pointer", aspectRatio: '16/9', objectFit: 'contain',
                        }} src={config.BASE_IMAGE_URL + item.image}
                             alt={item.name}/>
                        <p style={{textAlign: "center", position: 'relative', bottom: '0'}}>
                            {item.name}
                        </p>
                    </div>
                </Slide>
            )
        }

        return allSlides;
    }

    return (
        <div className={'grid ui'}>
            <TitleImageComponent
                textStore={textStore}
                namespace={namespace}
                imageLinkId={`${namespace}-title-image-id`}
                headerId={`${namespace}-title-header-id`}
                subheaderId={`${namespace}-subtitle-header-id`}
            />
            <div className={'row centered'}>
                <div className={"two wide column"}/>
                <div className={'fourteen wide column sixteen wide tablet mobile'}>
                    <Breadcrumb className={"breadcrumb-container"}>
                        <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                            history.push("/");
                        }}>
                            HOME</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron'/>
                        <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                            history.push("/spendensammlung");
                        }}>
                            SPENDENSAMMLUNG</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron'/>
                        <Breadcrumb.Section
                            className={"active-breadcrumb nav-item-green"} onClick={() => {
                            history.push("/spendensammlung/" + projectToShow.id);
                        }}>{projectToShow.header.toUpperCase()}</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'fourteen wide column'} style={{marginBottom: '2rem'}}>
                    <h1>{projectToShow.header}</h1>
                    <p>{projectToShow.description}</p>
                </div>
                <div className={'seven wide column'} style={{margin: 'auto'}}>
                    <img style={{width: '100%', padding: '5rem'}} alt={'project image'}
                         src={projectToShow.thumbnail}/>
                </div>
                <div className={'seven wide column'} style={{margin: 'auto'}}>
                    <div>
                        <h2>Bisher gesammelt</h2>
                        <h3>{projectToShow.currentDonationAmount}€</h3>
                        <h2>Zielbetrag</h2>
                        <h3>{projectToShow.goal}€</h3>
                    </div>
                    <div>
                        <Progress size={'large'} indicating style={{marginTop: '1rem', marginBottom: '1rem'}}
                                  progress={'value'} value={projectToShow.currentDonationAmount}
                                  total={projectToShow.goal}/>
                    </div>
                    <div>
                        <Button style={{marginLeft: '80%', marginTop: '1rem', marginBottom: '2rem'}}
                                className={'call-to-action-button'} circular onClick={_ => {
                            history.push("/donate?donate-anchor")
                        }}>Jetzt Spenden</Button>

                    </div>
                    <div>
                        <p><b><h3>Bankverbindung:</h3><br/>
                            Kreissparkasse Ravensburg<br/>
                            IBAN: DE24 6505 0110 0101 0202 02<br/>
                            BIC: SOLADES1RVB<br/>
                            Verwendungszweck: {projectToShow.header}</b></p>
                        {renderHTML(projectToShow.text)}
                        {partners.length > 0 ?
                            <>
                                <h2>Partner</h2>
                                <CarouselProvider
                                    naturalSlideWidth={1}
                                    naturalSlideHeight={0.5}
                                    isPlaying
                                    infinite
                                    visibleSlides={getWindowSize() <= 787 ? 1 : 3}
                                    totalSlides={3}>
                                    <Slider>
                                        {sliderItems()}
                                    </Slider>
                                    {partners.length > 3 ?
                                        <>
                                            <ButtonBack>
                                                <Icon name={'angle left'} inverted size={"large"} bordered circular/>
                                            </ButtonBack>
                                            <ButtonNext>
                                                <Icon name={'angle right'} inverted size={"large"} bordered circular/>
                                            </ButtonNext>
                                        </>
                                        :
                                        null}
                                </CarouselProvider></> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}))

export default FundraisingProjectPage