import config from "../../config/main.config";

export {
    getPartnerById
}


function getPartnerById(id){
    return new Promise(resolve => fetch(config.BASE_URL + 'partner/'+id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        }
    }).catch((e) => {
        console.log(e);
    }));
}